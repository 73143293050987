import React from "react"
import Layout from "../components/layout"
import {Link} from "gatsby"
import {HelmetDatoCms} from "gatsby-source-datocms"

export default class Service extends React.Component {
    render() {
        return (
            <Layout className="solid">
                <HelmetDatoCms>
                    <title>Falve - Error 500</title>
                    <meta name="description" content="Internal Server Error"/>
                </HelmetDatoCms>
                <div className="main-error">
                    <p>500</p>
                    <Link to="/" className="btn btn--yellow">Powrt do strony głównej</Link>
                </div>
            </Layout>
        )
    }
}
